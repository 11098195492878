<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="奖励明细"
        >
          <!-- <template slot="extra">
                        <a-button
                          class="editable-add-btn"
                          @click="handleAdd"
                          size="small"
                        >
                          新增
                        </a-button>
                      </template> -->
        </a-page-header>
      </div>
      <div ref="form">
        <a-form
          :form="form"
          layout="inline"
          @submit="handleSubmit"
          style="padding: 15px 0"
          ref="form"
        >
          <a-form-item label="活动审核ID">
            <a-input
              v-decorator="['share_code_id', { rules: [{ required: false }] }]"
            />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getExport(0)">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="columns"
        :data-source="rewardList"
        :pagination="false"
        :scroll="{ x: 1000, y: table_h }"
        :row-key="(record) => record.id"
        size="middle"
        ref="tableList"
      >
        <div slot="pm" slot-scope="pm">
          <span v-if="pm == '1'">增加</span>
          <span v-if="pm == '0'">减少</span>
        </div>
      </a-table>
      <div ref="pagination">
        <a-pagination
          v-if="pageshow"
          :default-current="currentPage"
          :defaultPageSize="defaultPageSize"
          :page-size-options="pageSizeOptions"
          show-size-changer
          show-quick-jumper
          :total="total_num"
          :show-total="(total) => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// import moment from "moment";
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      //   initialdate: null,
      //   startTime: "",
      //   endTime: "",
      columns: [
        {
          title: "活动审核ID",
          key: "share_code_id",
          dataIndex: "share_code_id",
          align: "center",
        },
        {
          title: "标题",
          key: "title",
          dataIndex: "title",
          align: "center",
        },
        {
          title: "时间",
          key: "created_at",
          dataIndex: "created_at",
          align: "center",
        },
        {
          title: "类型",
          key: "pm",
          dataIndex: "pm",
          scopedSlots: { customRender: "pm" },
          align: "center",
        },
        {
          title: "变动金额(元)",
          key: "number",
          dataIndex: "number",
          align: "center",
        },
        {
          title: "余额(元)",
          key: "balance",
          dataIndex: "balance",
          align: "center",
        },
        {
          title: "备注",
          key: "mark",
          dataIndex: "mark",
          align: "center",
          width: 200,
        },
      ],
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      rewardList: [],
    };
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), true);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.pagination.offsetHeight -
        60;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    // 日期切换
    onChange(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
    },
    
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      //   data.startTime = that.startTime;
      //   data.endTime = that.endTime;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/activityTask/rewardLog/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            console.log(res);
            var rewardList = res.data.data;
            that.changeTableH();
            that.rewardList = rewardList;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}
.ant-table-wrapper {
  flex: 1;
}
.goodsInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 250px;
}
.goodsInfo img {
  width: 80px;
  height: 80px;
}
.goodsInfo > div {
  width: 165px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 80px;
}
.goodsInfo > div > div {
  width: 165px;
  text-align: left;
}
/* .goodsInfo>div>div>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
} */
.goodsInfo > div > div span {
  color: #1890ff;
}
.goodsInfo p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}
.tuiguang {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.tuiguang div {
  text-align: left;
  flex: 1;
}
.orderTime {
  text-align: left;
}
</style>